import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useCan } from '../../../../../../contexts/ability.context';
import {
  MerchantGroupMemberAddMutation,
  MerchantGroupMemberRemoveMutation,
} from '../../../MerchantGroups/MerchantGroupView/Members/query';

const MerchantGroupsTable = ({ user }) => {
  const can = useCan();
  const [selectedRowKeys, setSelectedRowKeys] = useState(user.authorizedMerchantGroups.map((mg) => mg.id));

  const [add] = useMutation(MerchantGroupMemberAddMutation, {
    refetchQueries: ['ProgramManagerUserAuthorizationManagementQuery'],
  });
  const [remove] = useMutation(MerchantGroupMemberRemoveMutation, {
    refetchQueries: ['ProgramManagerUserAuthorizationManagementQuery'],
  });

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onSelect: (merchantGroup, selected, selectedRows) => {
      setSelectedRowKeys(selectedRows.map((r) => r.id));
      if (selected) add({ variables: { id: merchantGroup.id, memberId: user.id } });
      else remove({ variables: { id: merchantGroup.id, memberId: user.id } });
    },
    getCheckboxProps: () => ({
      disabled: !can('update-access', 'program-manager-user'),
    }),
  };

  return (
    <div>
      <Table
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            ...(can('read', 'merchant-group')
              ? {
                  render: (name, row) => <Link to={`/merchant-groups/${row.id}`}>{name}</Link>,
                }
              : {}),
          },
          { key: 'description', dataIndex: 'description' },
        ]}
        dataSource={user.programManager.merchantGroups}
        showHeader={false}
        rowSelection={rowSelection}
        rowKey="id"
        bordered={false}
        pagination={false}
      />
    </div>
  );
};

export default MerchantGroupsTable;
