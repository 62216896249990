import React from 'react';
import { Button, Image, message, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { CameraOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useCan } from '../../../../../../contexts/ability.context';
import InfoTile from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import ColorPicker from '../../../../../ThemeEditor/ColorPicker/ColorPicker';
import companyStyles from '../../../MerchantAccounts/MerchantAccountView/CompanyInformation/CompanyInformation.module.scss';
import styles from './ProgramManagerTheme.module.scss';
import { defaultTheme } from '../../../../../../util/theme';
import { useProgramManager } from '../../../../../../contexts/programManager.context';
import { ProgramManagerThemeImgUpload, programManagerThemeQuery, ProgramManagerThemeUpdate } from './query';
import LoaderDrawer from './LoaderDrawer/LoaderDrawer';
import useToggle from '../../../../../../hooks/useToggle';
import Loader from '../../../../Common/Loader/Loader';
import ImgUploader from '../../../../Common/Uploader/ImgUploader';

const ProgramManagerTheme = ({ id }) => {
  const can = useCan();

  const programManagerId = useProgramManager();

  const [isLoaderDrawerOpen, { toggle: toggleLoaderDrawer }] = useToggle(false);
  const [isLoaderPreviewOpen, { toggle: toggleLoaderPreview }] = useToggle(false);

  const {
    data: { programManager } = {
      programManager: null,
    },
  } = useQuery(programManagerThemeQuery, {
    variables: {
      id,
    },
  });

  const primaryColor = programManager?.theme?.primaryColor || defaultTheme.primaryColor;
  const secondaryColor = programManager?.theme?.secondaryColor || defaultTheme.secondaryColor;

  const [uploadProgramManagerThemeImg] = useMutation(ProgramManagerThemeImgUpload, {
    variables: {
      id,
    },
    onError: () => message.error('An error occurred, please try again later.'),
  });

  const handleUpload = (type) => (file) => {
    uploadProgramManagerThemeImg({ variables: { type, file } });
  };

  const [updateProgramManagerTheme] = useMutation(ProgramManagerThemeUpdate, {
    refetchQueries: ['ProgramManagerDomain'],
    onError: () => message.error('An error occurred, please try again later.'),
  });

  const handleChangeColor = (name) => (color) => {
    updateProgramManagerTheme({
      variables: {
        id,
        theme: {
          primaryColor,
          secondaryColor,
          [name]: color,
        },
      },
      optimisticResponse: {
        updateProgramManagerTheme: {
          id,
          theme: {
            ...programManager.theme,
            primaryColor,
            secondaryColor,
            [name]: color,
          },
        },
      },
    });
  };

  if (can('update', 'program-manager-theme')) {
    return (
      <InfoTile name="program-manager-theme" title={programManagerId ? 'Customization' : 'Program Manager Theme'}>
        <Space direction="vertical">
          <ImgUploader
            label={programManagerId ? 'Logo' : 'PM logo'}
            value={programManager?.theme?.logo}
            onChange={handleUpload('logo')}
            info="Recommended: SVG, ratio 4/1"
          />
          <ImgUploader
            label={programManagerId ? 'Icon' : 'PM icon'}
            value={programManager?.theme?.icon}
            onChange={handleUpload('icon')}
            info="Recommended: SVG, ratio 1/1"
          />
          <ImgUploader
            label={programManagerId ? 'Favicon' : 'PM favicon'}
            value={programManager?.theme?.favicon}
            onChange={handleUpload('favicon')}
            info="Recommended: PNG, ratio 1/1"
          />
          <ImgUploader
            label={programManagerId ? 'Background' : 'PM background'}
            value={programManager?.theme?.background}
            onChange={handleUpload('background')}
            info="Recommended: JPEG, good quality"
          />
          <Space>
            {programManager?.theme?.loader?.type ? (
              <div className={styles.upload}>
                <Space className={styles.btnContainer} size={4}>
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={toggleLoaderDrawer}
                  />
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={toggleLoaderPreview}
                  />
                </Space>
                <Loader loader={programManager.theme.loader} width={60} height={60} stopped />
                <Image
                  width={0}
                  style={{ display: 'none' }}
                  src={programManager.theme.loader.img}
                  preview={{
                    visible: isLoaderPreviewOpen,
                    src: programManager.theme.loader.img,
                    onVisibleChange: toggleLoaderPreview,
                  }}
                />
              </div>
            ) : (
              <Button className={styles.uploadBtn} onClick={toggleLoaderDrawer}>
                <CameraOutlined />
              </Button>
            )}
            Loader
          </Space>
          <ColorPicker
            label="Primary color"
            value={primaryColor}
            onChange={handleChangeColor('primaryColor')}
            description="Color applied on active/focused elements."
          />
          <ColorPicker
            label="Background color"
            value={secondaryColor}
            onChange={handleChangeColor('secondaryColor')}
            description="Color used on some backgrounds."
          />
        </Space>
        <LoaderDrawer
          programManagerId={id}
          visible={isLoaderDrawerOpen}
          onClose={toggleLoaderDrawer}
          loader={programManager?.theme?.loader}
        />
      </InfoTile>
    );
  }

  return (
    <InfoTile name="program-manager-theme" title={programManagerId ? 'Customization' : 'Program Manager Theme'}>
      <Space
        direction='vertical'
      >
        {programManager?.theme?.icon ? (
          <Space>
            <img
              src={programManager?.theme?.icon}
              alt="Company icon"
              className={companyStyles.companyLogo}
              style={{backgroundColor: secondaryColor, objectFit: 'contain', width: 58, height: 58}}
            />
            <div>
              Company icon
            </div>
          </Space>
        ) : 'No company icon'}
        {programManager?.theme?.logo ? (
          <Space>
            <img
              src={programManager?.theme?.logo}
              alt="Company logo"
              className={companyStyles.companyLogo}
              style={{backgroundColor: secondaryColor, objectFit: 'contain', height: 58}}
            />
            <div>
              Company logo
            </div>
          </Space>
        ) : 'No company logo'}
        <Space>
          <div className={companyStyles.colorBlock} style={{backgroundColor: primaryColor}}/>
          <div>
            Primary color
          </div>
        </Space>
        <Space>
          <div className={companyStyles.colorBlock} style={{backgroundColor: secondaryColor}}/>
          <div>
            Secondary color
          </div>
        </Space>
      </Space>
    </InfoTile>
  );
};

export default ProgramManagerTheme;
