import React, { useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Drawer, Form, Input, message, Select, Space } from 'antd';
import { useMetaProgramManagerId } from '../../../../../contexts/app.context';
import { compareByProp } from '../../../../../util/array';
import useNavigateWithSearch, { Link } from '../../../../../util/navigate';
import { searchByProp } from '../../../../../util/string';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import { MetaProgramManagerListQuery, MetaProgramManagerQuery, MetaProgramManagerUserCreateMutation } from './query';
import MetaProgramManagerAvatar from '../../../Common/MetaProgramManager/Avatar';
import { MODES, useMode } from '../../../../../contexts/mode.context';

const UserCreate = ({ visible, onCancel, metaProgramManager }) => {
  const navigate = useNavigateWithSearch();
  const [metaProgramManagerId] = useMetaProgramManagerId();
  const mode = useMode();

  const [selectedMetaProgramManager, setSelectedMetaProgramManager] = useState(metaProgramManager);

  const metaProgramManagersQuery = useQuery(MetaProgramManagerListQuery, { skip: !!metaProgramManager });
  const metaProgramManagerQuery = useQuery(MetaProgramManagerQuery, {
    variables: { id: selectedMetaProgramManager },
    skip: !selectedMetaProgramManager,
  });

  const [create, { loading }] = useMutation(MetaProgramManagerUserCreateMutation, {
    refetchQueries: ['MetaProgramManagerUserListQuery', 'MetaProgramManagerUsers'],
    onCompleted: ({ createMetaProgramManagerUser }) => {
      message.success('User successfully created');
      navigate(`/meta-program-users/${createMetaProgramManagerUser.id}`);
    },
    onError: (err) => message.error(err.message),
  });

  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: values,
      },
    });
  };

  const noAuthorizedEmailDomains = metaProgramManagerQuery.data?.metaProgramManager.emailDomains.length === 0;
  const noMetaProgramManagerAclProfiles =
    metaProgramManagerQuery.data?.metaProgramManager.metaProgramManagerAclProfiles.length === 0;
  const hideUserFields = !selectedMetaProgramManager || noAuthorizedEmailDomains || noMetaProgramManagerAclProfiles;

  return (
    <Drawer
      title={metaProgramManagerId ? 'Create Internal User' : 'Create Meta Program Manager User'}
      open={visible}
      onClose={onCancel}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 720, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="userForm" onFinish={submit}>
        {mode === MODES.INTERNAL && (
          <Form.Item
            name="metaProgramManager"
            label="Meta Program Manager"
            rules={[{ required: true }]}
            initialValue={metaProgramManager}
            hidden={!!metaProgramManager}
          >
            <Select
              showSearch
              filterOption={searchByProp('label')}
              optionLabelProp="label"
              loading={metaProgramManagersQuery.loading}
              disabled={!!metaProgramManager}
              onChange={(value) => {
                form.resetFields(['email']);
                setSelectedMetaProgramManager(value);
              }}
            >
              {metaProgramManagersQuery.data?.metaProgramManagers?.map((mpm) => (
                <Select.Option key={mpm.id} value={mpm.id} label={mpm.name}>
                  <Space>
                    <MetaProgramManagerAvatar metaProgramManager={mpm} />
                    {mpm.name}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {noAuthorizedEmailDomains && (
          <Alert
            type="warning"
            showIcon
            message={`Please set at least one authorized email domain for ${metaProgramManagerQuery.data?.metaProgramManager.name}`}
            action={
              <Link
                to={
                  metaProgramManagerId
                    ? '/meta-program-settings'
                    : `/meta-program-managers/${selectedMetaProgramManager}`
                }
              >
                <Button size="small" type="ghost" icon={<ArrowRightOutlined />} onClick={onCancel}>
                  Settings
                </Button>
              </Link>
            }
          />
        )}
        {noMetaProgramManagerAclProfiles && (
          <Alert
            type="warning"
            showIcon
            message={`Please set at least one profile for ${metaProgramManagerQuery.data?.metaProgramManager.name}`}
            action={
              <Link to="/meta-program-access-control-list">
                <Button size="small" type="ghost" icon={<ArrowRightOutlined />} onClick={onCancel}>
                  Settings
                </Button>
              </Link>
            }
          />
        )}
        <Form.Item
          name="email"
          label="Email"
          hidden={hideUserFields}
          rules={[
            { required: true },
            { type: 'email', message: "It's not a valid email." },
            {
              pattern: new RegExp(
                `@(${metaProgramManagerQuery.data?.metaProgramManager.emailDomains
                  .map((domain) => domain.replace('.', '\\.'))
                  .join('|')})$`,
              ),
              message:
                `${metaProgramManager ? 'Internal' : 'Meta Program Manager'} ` +
                `users must sign-in with authorized domains: ` +
                `${metaProgramManagerQuery.data?.metaProgramManager.emailDomains.join(', ')}.`,
            },
          ]}
        >
          <Input placeholder={`mail@${metaProgramManagerQuery.data?.metaProgramManager.emailDomains[0]}`} />
        </Form.Item>
        <Form.Item name="first_name" label="Firstname" rules={[{ required: true }]} hidden={hideUserFields}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Lastname" rules={[{ required: true }]} hidden={hideUserFields}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone" hidden={hideUserFields}>
          <Space>
            <Form.Item name={['phone', 'code']} noStyle>
              <Input style={{ width: 65 }} placeholder="31" prefix="+" />
            </Form.Item>
            <Form.Item name={['phone', 'number']} noStyle>
              <Input placeholder="123456789" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item name="aclProfile" label="Role" rules={[{ required: true }]} hidden={hideUserFields}>
          <Select
            options={metaProgramManagerQuery.data?.metaProgramManager.metaProgramManagerAclProfiles
              .map((p) => ({ value: p.id, label: p.name }))
              .sort(compareByProp('label', 'asc', 'string'))}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }} hidden={hideUserFields}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserCreate;
