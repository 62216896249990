import { gql } from '@apollo/client';
import {FragmentCompanyInfo} from '../../Company/query';

export const metaProgramManagerQuery = gql`
  query MetaProgramManager($id: ID!) {
    metaProgramManager(id: $id) {
      id
      name
      merchantDomain
      adminDomain
      website
      theme {
        logo
        icon
        primaryColor
        secondaryColor
      }
      programManagers {
        id
      }
    }
  }
`;

export const removeMetaProgramManagerMutation = gql`
  mutation RemoveMetaProgramManager($id: ID!) {
    removeMetaProgramManager(id: $id) {
      id
    }
  }
`;


export const updateMetaProgramManagerCompanyMutation = gql`
  mutation UpdateMetaProgramManagerCompanyMutation($id: ID!, $input: CompanyUpdateInput!) {
    updateMetaProgramManagerCompany(id: $id, input: $input) {
      id
      name
      company {
        ${FragmentCompanyInfo}   
      }
    }
  }
`;