import React, { useState } from 'react';
import { Button, Form, Input, Select, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { difference } from '../../../../../../util/array';
import InfoTile, { InfoTextRow } from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../MetaProgramManagerCompanyExecutive/MetaProgramManagerCompanyExecutive.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import ImpactedUsers from './ImpactedUsers';
import { metaProgramManagerOptionsQuery, updateMetaProgramManagerOptionsMutation } from './query';

const MetaProgramManagerOptions = ({ id }) => {
  const can = useCan();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState(false);
  const [emailDomains, setEmailDomains] = useState();

  const { data } = useQuery(metaProgramManagerOptionsQuery, { variables: { id } });

  const metaProgramManager = data?.metaProgramManager;

  const [updateOptions, { loading }] = useMutation(updateMetaProgramManagerOptionsMutation, { variables: { id } });

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="settings" title="Settings">
        <Form
          form={form}
          title="Options"
          layout="vertical"
          initialValues={metaProgramManager}
          onFinish={(input) =>
            updateOptions({
              variables: {
                input: {
                  displayName: input.displayName,
                  code: input.code,
                  metaToken: input.metaToken,
                  website: input.website,
                  notificationUrl: input.notificationUrl,
                  apiDomain: input.apiDomain,
                  emailDomains: input.emailDomains,
                },
              },
            }).then(() => setEditMode(false))
          }
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Display name"
            name="displayName"
            rules={[{ required: true }]}
            tooltip="Name displayed on the merchant console."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true }, { len: 4, message: 'Code should have 4 characters.' }]}
            tooltip="Technical code (e.g. used to prefix API keys like XXXX_PR_123456789...)."
          >
            <Input />
          </Form.Item>
          <Form.Item name="merchantDomain" label="Merchant domain" tooltip="Domain of merchant application.">
            <Input disabled />
          </Form.Item>
          <Form.Item name="adminDomain" label="Admin domain" tooltip="Domain of admin application.">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="alternativeMerchantDomain"
            label="Alternative merchant domain"
            tooltip={
              <>
                Alternative environment domain of merchant application.
                <br />
                Production / Sandbox
                <br />
                Staging / Development
              </>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="alternativeAdminDomain"
            label="Alternative admin domain"
            tooltip={
              <>
                Alternative environment domain of admin application.
                <br />
                Production / Sandbox
                <br />
                Staging / Development
              </>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="apiDomain"
            label="API domain"
            tooltip="Only used to route vanity urls."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Authorized email domains"
            name="emailDomains"
            tooltip="Authorized domains for users who access this Back Office. You can create new users based on this list of domains only. If a domain is deleted, users will not be able to connect anymore."
            onReset={() => setEmailDomains(undefined)}
          >
            <Select mode="tags" style={{ width: '100%' }} onChange={setEmailDomains} />
          </Form.Item>
          {emailDomains && (
            <ImpactedUsers programManager={id} emailDomains={difference(metaProgramManager.emailDomains, emailDomains)} />
          )}
          <Form.Item label="Meta token" name="metaToken" tooltip="Does the meta program manager support a meta token?">
            <Switch defaultChecked={!!metaProgramManager?.metaToken} disabled />
          </Form.Item>
          <Form.Item name="notificationUrl" label="Notification URL">
            <Input />
          </Form.Item>
          <Form.Item name="website" label="Website" tooltip="URL display as back link on login page.">
            <Input />
          </Form.Item>
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="settings"
      title="Settings"
      extra={
        can('update', 'meta-program-manager') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
    >
      <InfoTextRow label="Display name" value={metaProgramManager?.displayName} />
      <InfoTextRow label="Code" value={metaProgramManager?.code} />
      <InfoTextRow label="Merchant app domain" value={metaProgramManager?.merchantDomain} />
      <InfoTextRow label="Admin app domain" value={metaProgramManager?.adminDomain} />
      <InfoTextRow label="Alternative merchant app domain" value={metaProgramManager?.alternativeMerchantDomain} />
      <InfoTextRow label="Alternative admin app domain" value={metaProgramManager?.alternativeAdminDomain} />
      <InfoTextRow label="API domain" value={metaProgramManager?.apiDomain} />
      <InfoTextRow label="Authorized email domains" value={metaProgramManager?.emailDomains?.join(', ')} />
      <InfoTextRow label="Meta token" value={metaProgramManager?.metaToken ? 'True' : 'false'} />
      <InfoTextRow label="Notification URL" value={metaProgramManager?.notificationUrl} />
      <InfoTextRow label="Website" value={metaProgramManager?.website} />
    </InfoTile>
  );
};

export default MetaProgramManagerOptions;
