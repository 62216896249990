import React, { useState } from 'react';
import { Avatar, Button, List, Space, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { DATETIME2_FORMAT } from '../../../../constants/DATES';
import { initials } from '../../../../util/string';
import logoN from '../../../../assets/images/logo/n-white.svg';
import { useCan } from '../../../../contexts/ability.context';
import Page from '../../Common/Page/Page';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import ProgramManagerBadge from '../../Common/ProgramManager/Badge';
import styles from '../Company/CompanyList/CompanyList.module.scss';
import CreateAnnouncement from './CreateAnnouncement/CreateAnnouncement';
import announcementTypes from './announcementTypes';
import { AnnouncementsQuery, DeleteAnnouncementMutation } from './query';
import UpdateAnnouncement from './UpdateAnnouncement/UpdateAnnouncement';
import AnnouncementDetail from './AnnouncementDetail/AnnouncementDetail';
import { useProgramManagerId } from '../../../../contexts/app.context';
import {useMetaProgramManager} from '../../../../contexts/metaProgramManager.context';
import {useProgramManager} from '../../../../contexts/programManager.context';
import {MODES, useMode} from '../../../../contexts/mode.context';

const breadcrumbRender = () => (
  <Breadcrumb
    map={{
      '/': <HomeOutlined />,
      '/announcements': 'Announcements',
    }}
  />
);

const Announcements = () => {
  const can = useCan();
  const [programManagerId] = useProgramManagerId();
  const metaProgramManager = useMetaProgramManager();
  const programManager = useProgramManager();
  const mode = useMode();

  const announcementsQuery = useQuery(AnnouncementsQuery);
  const [deleteAnnouncement] = useMutation(DeleteAnnouncementMutation, {
    refetchQueries: ['AnnouncementsQuery'],
  });

  const [visible, setVisible] = useState(false);
  const [updateAnnouncement, setUpdateAnnouncement] = useState();
  const [detailAnnouncement, setDetailAnnouncement] = useState();

  const handleDelete =
    ({ id }) =>
    () =>
      deleteAnnouncement({ variables: { id } });

  const getAvatar = (item) => {
    if (item.createdBy) {
      return (
        <Avatar src={item.createdBy.avatar} title={item.createdBy.full_name} size="small">
          {initials(item.createdBy.full_name)}
        </Avatar>
      )
    }
    if (mode === MODES.PM && programManager.metaProgramManager) {
      return (
        <Avatar
          src={programManager.metaProgramManager.theme?.icon}
          style={{ backgroundColor: programManager.metaProgramManager.theme?.secondaryColor }}
          title={programManager.metaProgramManager.displayName}
          alt={programManager.metaProgramManager.displayName}
          size="small"
        >
          {programManager.metaProgramManager.displayName?.[0]}
        </Avatar>
      )
    }
    return (
      <Avatar src={logoN} style={{ backgroundColor: '#000000' }} title="NORBr" size="small">
        N
      </Avatar>
    )
  }

  return (
    <Page>
      <ErrorBoundary>
        <PageHeader
          title="Announcements"
          breadcrumbRender={breadcrumbRender}
          extra={[
            can('create', 'announcement') && (
              <Button key="create" type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
                Create announcement
              </Button>
            ),
          ]}
        >
          <Typography.Paragraph>
            Create and schedule announcements to deliver messages to the app users.
          </Typography.Paragraph>
          <List
            itemLayout="horizontal"
            dataSource={announcementsQuery.data?.announcements}
            loading={announcementsQuery.loading}
            bordered={false}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} announcements`,
            }}
            renderItem={(item) => {
              const { icon: Icon, color } = announcementTypes[item.type];

              let editable = moment().diff(item.triggerAt) < 0;

              if (mode === MODES.PM) {
                editable = editable && item.programManager?.id === programManagerId;
              }

              if (mode === MODES.META_PM) {
                editable = editable && item.metaProgramManager === metaProgramManager.id;
              }

              if (mode === MODES.INTERNAL) {
                editable = editable && !item.programManager && !item.metaProgramManager;
              }

              return (
                <ProgramManagerBadge programManager={item.programManager}>
                  <List.Item
                    className={classNames(styles.listItem, { [styles.disabled]: !editable })}
                    actions={[
                      ...(moment().diff(item.expireAt) > 0 ? [<Tag>Expired</Tag>] : []),
                      <Typography.Text type="secondary" title={moment(item.triggerAt).format(DATETIME2_FORMAT)}>
                        {moment().diff(item.triggerAt) > 0 ? 'sent' : 'scheduled'} {moment(item.triggerAt).fromNow()}
                        &nbsp;
                        <Space>
                          by
                          {getAvatar(item)}
                        </Space>
                      </Typography.Text>,
                      ...(!editable
                        ? [
                            <Button
                              key="detail"
                              type="text"
                              shape="circle"
                              icon={<EyeOutlined />}
                              title="View announcement"
                              onClick={() => setDetailAnnouncement(item)}
                            />,
                          ]
                        : []),
                      ...(editable && can('update', 'announcement')
                        ? [
                            <Button
                              key="update"
                              type="text"
                              shape="circle"
                              icon={<EditOutlined />}
                              title="Update announcement"
                              onClick={() => setUpdateAnnouncement(item)}
                            />,
                          ]
                        : []),
                      ...(editable && can('delete', 'announcement')
                        ? [
                            <Button
                              key="delete"
                              type="text"
                              shape="circle"
                              icon={<DeleteOutlined />}
                              title="Delete announcement"
                              onClick={handleDelete(item)}
                            />,
                          ]
                        : []),
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar icon={<Icon style={{ color }} />} style={{ backgroundColor: 'transparent' }} />}
                      title={item.title}
                      description={item.content}
                    />
                  </List.Item>
                </ProgramManagerBadge>
              );
            }}
          />
          <CreateAnnouncement key="create-announcement-drawer" visible={visible} onClose={() => setVisible(false)} />
          {updateAnnouncement && (
            <UpdateAnnouncement
              key="update-announcement-drawer"
              announcement={updateAnnouncement}
              visible={!!updateAnnouncement}
              onClose={() => setUpdateAnnouncement(null)}
            />
          )}
          {detailAnnouncement && (
            <AnnouncementDetail
              key="detail-announcement-drawer"
              announcement={detailAnnouncement}
              visible={!!detailAnnouncement}
              onClose={() => setDetailAnnouncement(null)}
            />
          )}
        </PageHeader>
      </ErrorBoundary>
    </Page>
  );
};

export default Announcements;
