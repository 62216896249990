import { gql } from '@apollo/client';

export const AnnouncementsQuery = gql`
  query AnnouncementsQuery {
    announcements {
      id
      type
      title
      content
      body
      internalAclProfiles {
        id
        name
      }
      programManagers {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      metaProgramManagers {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      programManagerAclProfiles {
        id
        name
        programManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
      metaProgramManagerAclProfiles {
        id
        name
        metaProgramManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
      merchantCompanies {
        id
        name
        theme {
          primaryColor
          secondaryColor
          icon
        }
      }
      merchantAclProfiles {
        id
        name
        programManager {
          id
          name
          theme {
            icon
            primaryColor
            secondaryColor
          }
        }
      }
      triggerAt
      expireAt
      programManager {
        id
        name
        theme {
          icon
          primaryColor
          secondaryColor
        }
      }
      metaProgramManager
      createdBy {
        id
        full_name
        email
        avatar
      }
    }
  }
`;

export const DeleteAnnouncementMutation = gql`
  mutation DeleteAnnouncementMutation($id: ID!) {
    deleteAnnouncement(id: $id) {
      id
    }
  }
`;
export default {};
